import Vue from "vue";
import { mdiHome, mdiFileDocument, mdiAccountCash, mdiMonitorDashboard } from "@mdi/js";

interface MenuItem {
  id: number;
  icon: string;
  title: string;
  sub?: string | undefined;
  route?: string | undefined;
  expand: boolean;
  items?: MenuItem[] | undefined;
}

export default Vue.extend({
  name: "AppMenuComponent",
  data() {
    return {
      links: [
        { id: 1, icon: mdiHome, title: "Inicio", route: "/", expand: false },
        {
          id: 2,
          icon: mdiMonitorDashboard,
          title: "Administrar Cajeros",
          route: "/cajeros",
          expand: false,
        },
        {
          id: 3,
          icon: mdiFileDocument,
          title: "Buscar Operaciones",
          route: "/operaciones",
          expand: false,
        },
        {
          id: 4,
          icon: mdiAccountCash,
          title: "Iniciar Llamada",
          route: "/llamada",
          expand: false,
        },
      ] as MenuItem[],
    };
  },
  computed: {
    Nombre(): string {
      return (
        this.$store.getters["oidcStore/oidcUser"].family_name +
        " " +
        this.$store.getters["oidcStore/oidcUser"].given_name
      );
    },
    Iniciales(): string {
      const arr = this.Nombre.split(" ", 2);
      return (arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase();
    },
  },
});
